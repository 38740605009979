<template>
  <!-- 社会责任 -->
  <div>
    <div class="banner" id="aboutus_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.CSR")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Social Responsibility</i
        ></span
      >
    </div>

    <section class="ab-item1">
      <!-- 跳转 -->
      <!-- <a href="/">{{ $t("responsibility.Home") }}</a> >
      <a href="/aboutus/synopsis">{{ $t("responsibility.About JingXin") }}</a>
      >
      {{ $t("responsibility.Social Responsibility") }} -->
      <bread-crumb />
    </section>
    <div>
      <!-- 选择年份 -->
      <section class="content">
        <div>
          <!-- 当前年份 -->
          <div>{{ nowYear }}</div>
          <!-- 下拉框 -->
          <ul>
            <li @click="checkyear">
              <span>{{ $t("responsibility.Select Year") }}</span>
            </li>
            <ul
              :style="{
                height: showyear ? 47 * yearList.length + 'px' : '0px',
                transition: 'all,0.4s',
              }"
            >
              <li v-for="item in yearList" :key="item" @click="clickyear(item)">
                <i>{{ item }}</i>
              </li>
            </ul>
          </ul>
        </div>
        <!-- ===== -->
        <div class="select-content">
          <ul class="report-list no-type">
            <!-- v-if="this.$store.state.nowlanguage == 'zh'" -->
            <div class="loading_box" v-if="showLoading"></div>
            <li v-for="item in dataList" :key="item.id">
              <a
                :href="filterLang(item, 'FileUrl')"
                :title="filterLang(item, 'Title')"
                target="_blank"
              >
                <img :src="filterLang(item, 'ImgUrl')" alt="" />
                <p>
                  {{ filterLang(item, "Title") }}
                </p>
              </a>
            </li>
          </ul>
          <!-- <ul
            class="report-list no-type"
            v-if="this.$store.state.nowlanguage == 'tr'"
          >
            <div class="loading_box" v-if="showLoading"></div>
            <li v-for="item in dataList" :key="item.id">
              {{ item }}
              <a
                :href="item.FileUrl"
                :title="filterLang(item, 'Title')"
                target="_blank"
              >
                <img :src="filterLang(item, 'imgUrl')" alt="" />
                <p>
                  {{
                    $store.state.nowlanguage == "en"
                      ? item.enTitle
                      : filterLang(item, "Title")
                  }}
                </p>
              </a>
            </li>
          </ul>
          <ul
            class="report-list no-type"
            v-if="this.$store.state.nowlanguage == 'en'"
          >
            <div class="loading_box" v-if="showLoading"></div>
            <li v-for="item in dataList" :key="item.id">
              {{ item }}
              <a
                :href="item.FileUrl"
                :title="filterLang(item, 'Title')"
                target="_blank"
              >
                <img :src="filterLang(item, 'imgUrl')" alt="" />
                <p>
                  {{
                    $store.state.nowlanguage == "en"
                      ? item.enTitle
                      : filterLang(item, "Title")
                  }}
                </p>
              </a>
            </li>
          </ul> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Shehuizeren } from "@/api/aboutus";
import { filterLang } from "@/utils/LangChange";
import { Clickyear, getyearList } from "@/utils/clickyear";
import moment from "dayjs";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";

export default {
  data() {
    return {
      // 选择年份下拉框是否开启
      showyear: false,
      List: [],
      yearList: [],
      nowYear: "", //当前年份
      dataList: [], //最终渲染数据的数组
      showLoading: true,
      banner: "",
    };
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.SHEHUIZEREN,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
        if (res.result.records[0].isMultipatLanguge !== "0") {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        } else {
          that.banner = res.result.records[0].sampleImg;
        }
      }
    });

    // 进入页面第一次发送请求
    Shehuizeren().then((res) => {
      this.showLoading = false;
      this.List = res.result;
      // 拿到所有数据的年份
      this.yearList = getyearList(res.result);
      if (this.yearList instanceof Array && this.yearList.length > 0) {
        this.nowYear = this.yearList[0];
      } else {
        this.nowYear = moment(new Date()).year();
      }
      this.dataList = Clickyear(this.List, this.nowYear);
    });
  },
  methods: {
    filterLang,
    // 点击选择年份展开下拉框
    checkyear() {
      this.showyear = !this.showyear;
    },
    // 选择年份 过滤数据
    clickyear(year) {
      let datas = [];
      // 如果点击的是当前年份,return
      if (this.nowYear == year) {
        this.showyear = false;
        return;
      }
      // 过滤传入的年数
      this.dataList = Clickyear(this.List, year);
      this.$forceUpdate();
      //  下拉框是否展开
      this.showyear = false;
      // 左侧显示当前年份
      this.nowYear = year;
    },
  },
};
</script>

<style scpoed lang=less>
.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}
section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}
.content {
  > div:first-child {
    > div {
      display: block;
      float: left;
      color: #18325f;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    border-bottom: 5px solid #18325f;
    > ul {
      position: relative;
      top: 1px;
      z-index: 1000;
      > li {
        color: #fff;
        cursor: pointer;
        background: #18325f;
        padding: 10px 58px 10px 15px;
        position: relative;
        > span {
          font-size: 14px;
        }
      }
      > ul {
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        border-radius: 0px !important;
        overflow: hidden;
        > li {
          font-size: 14px;
          padding: 14px 0;
          color: #fff;
          cursor: pointer;
          background: #18325f;
        }
        > li:hover {
          background: #747474 !important;
        }
      }
    }
  }
}
.content div > ul > li > span:after {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  position: absolute;
  top: 17px;
  right: 11px;
  z-index: 11;
  border-top: 8px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.select-content {
  padding-bottom: 30px;
  overflow: hidden;
  min-height: 360px;
}
.report-list {
  padding-top: 20px;
  width: 90%;
  overflow: hidden;
}
.report-list li {
  text-align: center;
  display: table;
  float: left;
  width: 33%;
  padding: 50px 0;
}
.report-list li a {
  color: #ffffff;
  display: table;
  position: relative;
  margin: auto;
  width: 200px;
  height: 270px;
  font: 14px/2 "宋体", Arial;
}

.report-list li a img {
  display: table;
  margin: auto;
  width: 100%;
  box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.5);
}
.report-list li a img:hover {
  box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.8);
}
.report-list li a p {
  padding-top: 20px;
  color: #232323;
  padding-bottom: 0;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
@media screen and (max-width: 710px) {
  .report-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .report-list li {
    margin: 30px;
  }
}
</style>
